var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex mt-n2" },
    [
      _vm.hasCustomRules && _vm.bookingRules.length
        ? _vm._l(_vm.templateSections, function ({ title, type }) {
            return _c(
              "div",
              { key: title, staticClass: "mr-4" },
              [
                _c("div", { staticClass: "emobg-font-weight-semibold pb-2" }, [
                  _vm._v(" " + _vm._s(title) + " "),
                ]),
                _vm._l(_vm.getRulesByType(type), function (rule) {
                  return _c(
                    "div",
                    { key: `${rule.type}__${rule.rule}`, staticClass: "pb-1" },
                    [
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                `CRM.Companies.FleetSegments.bookingRules.rules.${rule.rule}.title`
                              )
                            ) + ": "
                          ),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.formatValue(rule)))]),
                    ]
                  )
                }),
              ],
              2
            )
          })
        : _c("div", { staticClass: "d-flex flex-column" }, [
            _c("div", { staticClass: "emobg-font-weight-semibold pb-2" }, [
              _vm._v(" Booking rules "),
            ]),
            _c("div", [
              _vm._v(
                " Operator's default values are managed directly in the database. "
              ),
            ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }