export const FLEET_SEGMENT_ERRORS = {
  vehiclesCannotBeRemoved: 'fleet_segments.vehicles.cannot_be_removed',
  vehiclesCannotBeAdded: 'fleet_segments.vehicles_cannot_be_added',
  fleetSegmentCannotBeRemoved: 'fleet_segments.cannot_be_deleted',
  conflictingBookingRules: 'fleet_segments.conflicting_booking_rules',
};

export const FLEET_SEGMENT_SCOPES = {
  edit: 'edit',
  create: 'create',
};

export const BOOKING_RULES = {
  minDurationMinutes: 'min_duration_minutes',
  maxDurationMinutes: 'max_duration_minutes',
  bookingGap: 'booking_gap',
  autoCancelMinutes: 'auto_cancel_minutes',
  maxFutureBookingMinutes: 'max_future_booking_minutes',
};
