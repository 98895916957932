<script>
import { MuiCollapse } from '@emobg/motion-ui/v1';

export default {
  components: {
    MuiCollapse,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    openText: {
      type: String,
      default: 'Details',
    },
    closeText: {
      type: String,
      default: 'Details',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSectionOpen: this.isOpen,
    };
  },
  computed: {
    buttonSectionClass() {
      return this.isSectionOpen
        ? 'emobg-color-ink emobg-background-color-ground-light emobg-background-color-ground-hover'
        : 'emobg-color-ink-light emobg-background-color-none emobg-color-ink-hover';
    },
  },
};
</script>
<template>
  <div class="FormCollapseSection">
    <div class="d-flex align-items-center justify-content-between emobg-background-color-ground-lightest p-3">
      <h2 v-html="title" />
      <ui-button
        :face="FACES.outline"
        :color="GRAYSCALE.inkLight"
        :class="`${buttonSectionClass} hydrated`"
        @clickbutton="isSectionOpen = !isSectionOpen"
      >
        <div class="d-flex align-items-center">
          {{ isSectionOpen ? openText : closeText }}
          <ui-icon
            :icon="isSectionOpen ? ICONS.arrowUp : ICONS.arrowDown"
            :size="ICONS_SIZES.medium"
            class="ml-1"
          />
        </div>
      </ui-button>
    </div>
    <MuiCollapse :open="isSectionOpen">
      <div v-show="isSectionOpen">
        <slot />
      </div>
    </MuiCollapse>
  </div>
</template>
