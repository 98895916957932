var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "FormCollapseSection" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-between emobg-background-color-ground-lightest p-3",
        },
        [
          _c("h2", { domProps: { innerHTML: _vm._s(_vm.title) } }),
          _c(
            "ui-button",
            {
              class: `${_vm.buttonSectionClass} hydrated`,
              attrs: { face: _vm.FACES.outline, color: _vm.GRAYSCALE.inkLight },
              on: {
                clickbutton: function ($event) {
                  _vm.isSectionOpen = !_vm.isSectionOpen
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isSectionOpen ? _vm.openText : _vm.closeText) +
                      " "
                  ),
                  _c("ui-icon", {
                    staticClass: "ml-1",
                    attrs: {
                      icon: _vm.isSectionOpen
                        ? _vm.ICONS.arrowUp
                        : _vm.ICONS.arrowDown,
                      size: _vm.ICONS_SIZES.medium,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("MuiCollapse", { attrs: { open: _vm.isSectionOpen } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSectionOpen,
                expression: "isSectionOpen",
              },
            ],
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }