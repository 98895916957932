<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapState } from 'vuex';
import { formatStopWatch, isFalsyString, PRECISION } from '@emobg/web-utils';
import { BOOKING_TYPES } from '@/domains/Carsharing/const/bookingTypes';
import { BOOKING_RULES } from '../../../const/fleetSegment';

export default {
  props: {
    hasCustomRules: {
      type: Boolean,
      default: false,
    },
    bookingRules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      companyConfig: state => get(state, 'config.data'),
    }),
    isLongDistanceAllowed() {
      return get(this.companyConfig, 'longDistanceAllowed', 0);
    },
    isAutoCancelAllowed() {
      return get(this.companyConfig, 'allowAutoCancel', 0);
    },
    templateSections() {
      const sections = [
        {
          title: 'Carsharing booking rules',
          type: BOOKING_TYPES.carsharing,
        },
        {
          title: 'General booking rules',
          type: null,
        },
      ];

      if (this.isLongDistanceAllowed) {
        sections.splice(1, 0, {
          title: 'Long distance booking rules',
          type: BOOKING_TYPES.longDistance,
        });
      }

      return sections;
    },
  },
  created() {
    this.BOOKING_TYPES = BOOKING_TYPES;
  },
  methods: {
    formatValue({ value, rule, type }) {
      const precisionRules = {
        [BOOKING_RULES.minDurationMinutes]: type === BOOKING_TYPES.longDistance ? PRECISION.days : PRECISION.hours,
        [BOOKING_RULES.maxDurationMinutes]: type === BOOKING_TYPES.longDistance ? PRECISION.days : PRECISION.hours,
        [BOOKING_RULES.maxFutureBookingMinutes]: PRECISION.days,
        [BOOKING_RULES.bookingGap]: PRECISION.minutes,
        [BOOKING_RULES.autoCancelMinutes]: PRECISION.minutes,
        default: PRECISION.minutes,
      };
      return !isFalsyString(value) ? formatStopWatch(value * 60, precisionRules[rule]) : 'No custom value';
    },
    getRulesByType(bookingType) {
      return filter(this.bookingRules, ({ type, rule }) => {
        const matchesType = `${bookingType}` === `${type}`;
        return !this.isAutoCancelAllowed ? matchesType && rule !== BOOKING_RULES.autoCancelMinutes : matchesType;
      });
    },
  },
};
</script>
<template>
  <div class="d-flex mt-n2">
    <template v-if="hasCustomRules && bookingRules.length">
      <div
        v-for="({ title, type }) in templateSections"
        :key="title"
        class="mr-4"
      >
        <div class="emobg-font-weight-semibold pb-2">
          {{ title }}
        </div>
        <div
          v-for="rule in getRulesByType(type)"
          :key="`${rule.type}__${rule.rule}`"
          class="pb-1"
        >
          <span class="emobg-font-weight-semibold">{{ $t(`CRM.Companies.FleetSegments.bookingRules.rules.${rule.rule}.title`) }}: </span>
          <span>{{ formatValue(rule) }}</span>
        </div>
      </div>
    </template>
    <div
      v-else
      class="d-flex flex-column"
    >
      <div class="emobg-font-weight-semibold pb-2">
        Booking rules
      </div>
      <div>
        Operator's default values are managed directly in the database.
      </div>
    </div>
  </div>
</template>

