import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const BOOKING_TYPES = {
  carsharing: 'carsharing',
  longDistance: 'long_distance',
  intervention: 'intervention',
  unavailability: 'unavailability',
};

export const BOOKING_TYPE_BADGES = {
  carsharing: {
    color: COLORS.corporatePrimary,
    square: true,
  },
  longDistance: {
    'border-modifier': 'darker',
    'background-modifier': 'null',
    color: COLORS.accent,
    square: true,
    contrast: true,
  },
  intervention: {
    color: COLORS.warning,
    square: true,
  },
  unavailability: {
    color: GRAYSCALE.ground,
    square: true,
  },
};

export const BLOCK_TYPES = {
  unavailability: 'unavailability',
  dedicatedHours: 'dedicated-hours',
};

export const TRIP_TYPES = {
  oneWay: 'one_way',
  roundTrip: 'roundTrip',
};
