import {
  filter,
  flatMap,
  get,
  head,
  isEmpty,
  join,
  map,
  nth,
  reject,
  values,
} from 'lodash';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';
import BookingRulesTemplate from '../components/BookingRulesTemplate';

export const isAlwaysWorkingHours = workingHours => {
  if (isEmpty(workingHours)) {
    return false;
  }
  return isEmpty(reject(workingHours, day => head(day) === '00:00-24:00'));
};

export const getWorkingHoursTypeLabel = result => {
  if (isEmpty(flatMap(get(result, 'working_hours.days')))) {
    return 'Not Dedicated';
  }

  if (!isAlwaysWorkingHours(get(result, 'working_hours.days')) && !get(result, 'allow_personal_dedicated')) {
    return 'Dedicated when scheduled - Personal use';
  }

  return isAlwaysWorkingHours(get(result, 'working_hours.days'))
    ? 'Always dedicated'
    : 'Dedicated when scheduled - Open use';
};

export const isSchedule = schedule => {
  const days = values(schedule);
  const filteredDays = filter(days, day => !isEmpty(day));
  return !isEmpty(filteredDays);
};

export const renderSchedule = (schedule, fallback) => {
  if (isEmpty(schedule) || isAlwaysWorkingHours(schedule)) {
    return fallback;
  }
  const entries = filter(Object.entries(schedule), times => !isEmpty(nth(times, 1)));
  const template = (dayName, timetable) => `<div class="d-flex pb-1">${dayName}<span class="pl-2">${timetable}</span></div>`;
  return join(map(entries, workingHour => {
    const dayName = sentenceCase(head(workingHour)).substring(0, 3);
    const dayNode = `<span style="width:30px;" class="emobg-font-weight-bold">${dayName}:</span>`;
    const timetable = join(nth(workingHour, 1), ' - ') || 'open use';
    return template(dayNode, timetable);
  }), '');
};

export const contentCells = [
  {
    title: 'Name',
    displayPriority: 1,
    columnRatio: 1,
    type: RECORD_TYPES.component,
    minWidth: 120,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: crm.companies.detail.fleetSegments.edit,
        params: {
          userCompanyUuid: get(result, 'company.uuid'),
          fleetSegmentUuid: result.uuid,
        },
      },
      linkPermissions: [
        CRM_PERMISSIONS.viewCrmCompanies,
        CRM_PERMISSIONS.viewFleetSegments,
        CRM_PERMISSIONS.manageFleetSegments,
      ],
      text: result.name,
    }),
  },
  {
    attributeName: 'vehicles',
    minWidth: 30,
    displayPriority: 1,
    columnRatio: 1,
    title: 'Vehicles',
    transformValue: vehicles => vehicles.length,
  },
  {
    attributeName: '',
    minWidth: 80,
    displayPriority: 1,
    columnRatio: 1,
    transformResult: getWorkingHoursTypeLabel,
    title: 'Dedicated fleet',
  },
  {
    attributeName: 'custom_booking_rules',
    minWidth: 75,
    displayPriority: 1,
    columnRatio: 1,
    transformValue: value => (value ? 'Custom values' : 'Operator\'s default values'),
    title: 'Booking rules',
  },
  {
    title: 'Dedicated fleet schedule',
    minWidth: 180,
    attributeName: 'working_hours',
    type: RECORD_TYPES.template,
    isCollapseHidden: true,
    columnRatio: 2,
    template: result => {
      const workingHours = get(result, 'working_hours.days') || get(result, 'working_hours');
      return renderSchedule(workingHours, FALLBACK_MESSAGE.dash);
    },
  },
  {
    attributeName: 'description',
    minWidth: 120,
    columnRatio: 2,
    isCollapseHidden: true,
    title: 'Description',
  },
  {
    attributeName: 'booking_rules',
    type: RECORD_TYPES.component,
    component: BookingRulesTemplate,
    props: result => ({ bookingRules: result.booking_rules, hasCustomRules: result.custom_booking_rules }),
    isCollapseHidden: true,
    title: null,
  },
];
